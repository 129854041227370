import { Container, Row, Col } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';

function Services() {
  return (
   <Container> 
   
    <Row>
      <Col md={'6'}>
      <img src={require('./dispatcher.png')} alt="" style={{width:'100%',height:'auto',marginBottom:10}} />
      </Col>
      <Col md={'6'}>
      <h1>Services</h1>
        <ListGroup>

        <ListGroup.Item href='/creditrepair' action>Credit Repair</ListGroup.Item>
        <ListGroup.Item href='/claim' action>Worker's Compensation</ListGroup.Item>
        <ListGroup.Item href='/student' action>International Student</ListGroup.Item>
        <ListGroup.Item href='/loan' action>Business Loan</ListGroup.Item>
        <ListGroup.Item href='/personal' action>Personal loan</ListGroup.Item>
        <ListGroup.Item href='/company' action>Company (LLC, INC, CORP, LTD)</ListGroup.Item>
        <ListGroup.Item href='/upload' action>Credit Card, Debit Card, Bank Account</ListGroup.Item>
        <ListGroup.Item href='/consultation' action>Consultation</ListGroup.Item>
        <ListGroup.Item href='/account' action>Account</ListGroup.Item>

        {/* <ListGroup.Item href='/privacy' action>Privacy Policy</ListGroup.Item>
        <ListGroup.Item href='/terms' action>Terms and Conditions</ListGroup.Item>
        <ListGroup.Item href='/eula' action>EULA</ListGroup.Item> */}

        </ListGroup>
      </Col>
    </Row>

    <br />

    <a href="/consultation"><button className='btn btn-primary'>Schedule An Appointment</button></a>
   </Container>
  );
}

export default Services;