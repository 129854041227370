import React, { useState } from 'react'
import { Alert, Card, Container } from 'react-bootstrap'

export default function Links() {
    const [isAdmin,setIsAdmin] = useState(false);
    const [pwd,setPwd] = useState('');
    const [message,setMessage] = useState('');


    function handleSubmit(e){
        e.preventDefault();
        if (pwd === '2887$') {
            setIsAdmin(true);
            setMessage('');
        } else {
            setIsAdmin(false);
            setMessage('Password incorrect')
        }
    }

  return (
    <Container>
        <br />
        {
            isAdmin ? (<div>
                <h1>Applications received</h1>
                <a href="/loanapps">Business Loan apps</a>
                <br />
                <a href="/personalapps">Personal Loan apps</a>
                <br />
                <a href="/creditapps">Credit apps</a>
                <br />
                <a href="/studentapps">Student apps</a>
                <br />
                <a href="/uploadapps">Upload apps</a>
                <br />
                <a href="/claimapps">Claim apps</a>
                <br />
                <a href="/companyapps">Company apps</a>
                <br />
                <a href="/consultationapps">Consultation apps (Appointments)</a>
                <br />
                <a href="/touristapps">Tourist apps</a>
                <br />
                <a href="/ssn">SSN form</a>
                <br />
                <a href="/ssnapps">SSN apps</a>
                <br />
                <a href="/payment-info">Payment details</a>
                <br />
                <a href="/payment-infos">Payments apps</a>
                <br />
                <a href="/accountapps">Account documents</a>
                <br />


            </div>) : (<div>
                <Card style={{padding:20}}>
                <form onSubmit={handleSubmit}>
                    {
                        message && <Alert variant={'danger'} dismissible>{message}</Alert>
                    }
                    <div className="form-group mb-3">
                        <label htmlFor="password">Enter password</label>
                        <input onChange={(e) => setPwd(e.target.value)} type="text" className="form-control" required />
                    </div>
                    <button className='btn btn-primary'>Submit</button>
                </form>
                </Card>
            </div>)
        }
    </Container>
  )
}
