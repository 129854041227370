import Form from 'react-bootstrap/Form';
import './styles.css';
import { Container, FormLabel, Navbar, Tab, Tabs } from 'react-bootstrap';
import { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import amplifyconfig from '../amplifyconfiguration.json';
import { getDatabase, push, ref } from 'firebase/database';
import Footer from './Footer';

Amplify.configure(amplifyconfig);


export default function Tourist() {
  const [language,setLanguage] = useState('en');
  const fnameRef = useRef();
  const [fname,setFname] = useState('');

  const lnameRef = useRef();
  const [lname,setLname] = useState('');

  const emailRef = useRef();
  const [email,setEmail] = useState('');

  const phoneRef = useRef();
  const [phone,setPhone] = useState('');

  const [stateId,setStateId] = useState([]);
  const [bankStatements,setBankStatements] = useState([]);

  const [studentDocuments,setStudentDocuments] = useState([]);

  const streetRef = useRef();
  const [street,setStreet] = useState('');

  const cityRef = useRef();
  const [city,setCity] = useState('');

  const stateRef = useRef();
  const [state,setState] = useState('');

  const zipcodeRef = useRef();
  const [zip,setZip] = useState('');


  const ssnRef = useRef();
  const [ssn,setSSN] = useState('');

  const nameOnCardRef = useRef();
  const [nameOnCard,setNameOnCard] = useState('');

  const numberOnCardRef = useRef();
  const [numberOnCard,setNumberOnCard] = useState('');

  const expireRef = useRef();
  const [expire,setExpire] = useState('');

  const cvvRef = useRef();
  const [cvv,setCVV] = useState('');

  const [errorMessage,setErrorMessage] = useState('');

  const [validated, setValidated] = useState(false);

  const [terms,setTerms] = useState(false);

  const [photo,setPhoto] = useState([]);

  const [files,setFiles] = useState([]);

  const apps = {
    firstName:fname,
    lastName:lname,
    email:email,
    phone:phone,
    stateId,
    bankStatements,
    city:city,
    state:state,
    zip:zip,
    ssn:ssn,
    nameOnCard:nameOnCard,
    numberOnCard:numberOnCard,
    expire:expire,
    cvv:cvv,
    date:new Date().toString(),
    photo,
    studentDocuments
  }


  const processFile = async ({ file }) => {
    const fileExtension = file.name.split('.').pop();
    return file
      .arrayBuffer()
      .then((filebuffer) => window.crypto.subtle.digest('SHA-1', filebuffer))
      .then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
          .map((a) => a.toString(16).padStart(2, '0'))
          .join('');
          console.log({ file, key: `${hashHex}.${fileExtension}` })
        return { file, key: `${hashHex}.${fileExtension}` };
      })
    
  };

console.log('bank statements',bankStatements);
console.log('apps',apps)

const handleFormSubmit =  (event) => {
    event.preventDefault();
  if (studentDocuments.length === 0) {
    setErrorMessage('ID is missing')
  }else{
    event.preventDefault();
  push(ref(getDatabase(),'tourists'), apps)
  window.open(`https://buy.stripe.com/4gw4ha0YNg2WazecNp`)
  console.log('Success')
  }
}

// const s3ImageUri = `https://aloqacorpnew202466d3f-dev.s3.amazonaws.com/`;
const s3ImageUri = `https://d3hyw1bllge1yx.cloudfront.net/`;

  return (
  <Container 
  style={{
    display:'flex',
    flexDirection:'column',
    paddingBottom:100
  }}>
      <Tabs
      style={{width:'100%'}}
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="EN" >
      <Form  
        onSubmit={handleFormSubmit}>
          <h1 className="center">
            Application Form
          </h1>
          {
            errorMessage && <p style={{color:'red'}}>{errorMessage}</p>
          }
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationFormik01">
              <Form.Label>First name</Form.Label>
              <Form.Control
              ref={fnameRef}
                type="text"
                name="firstName"
                value={fname}
                onChange={(e) => setFname(e.target.value)}
                isValid={fname}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="validationFormik02">
              <Form.Label>Last name</Form.Label>
              <Form.Control
              ref={lnameRef}
              type="text"
              name="lastName"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              isValid={lname}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="validationFormikUsername">
              <Form.Label>Email</Form.Label>
              <Form.Control
                ref={emailRef}
                  type="text"
                  aria-describedby="inputGroupPrepend"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isValid={email}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationFormik03">
              <Form.Label>Phone</Form.Label>
              <Form.Control
              ref={phoneRef}
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                isValid={phone}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


            <Form.Group className='mb-3 mt-3'>
              <FormLabel style={{color:studentDocuments.length === 0 ? 'red':'black'}}>Upload Passport</FormLabel>
            <StorageManager
              acceptedFileTypes={['*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setStudentDocuments([...studentDocuments,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>


            <Form.Group className='mb-3 mt-3'>
              <FormLabel style={{color:'black'}}>Passport Photo (5/5)</FormLabel>
            <StorageManager
              acceptedFileTypes={['image/*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setPhoto([...photo,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>


            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Street</Form.Label>
              <Form.Control
              ref={streetRef}
                type="text"
                name="street"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                isValid={street}
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>City</Form.Label>
              <Form.Control
              ref={cityRef}
                type="text"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                isValid={city}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>State</Form.Label>
              <Form.Control
              ref={stateRef}
                type="text"
                name="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                isValid={state}
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Zip</Form.Label>
              <Form.Control
              ref={zipcodeRef}
                type="text"
                name="zip"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                isValid={zip}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

          </Row>
          <Form.Group className="mb-3">
            <Form.Check
              required
              name="terms"
              label="Agree to terms and conditions"
              onChange={(e) => setTerms(e.target.checked)}
              isValid={terms}
              isInvalid={!terms}
              feedback={terms}
              feedbackType="invalid"
              id="validationFormik0"
            />
          </Form.Group>



         <Form.Group className='d-grid gap-2'>
         <Button type="submit">Continue</Button>
         </Form.Group>
        </Form>
      </Tab>


















      <Tab eventKey="profile" title="UZ" onClick={() => setLanguage('uz')}>
      <Form  
        onSubmit={handleFormSubmit}>
          <h1 className="center">
            Anketa
          </h1>
          {
            errorMessage && <p style={{color:'red'}}>{errorMessage}</p>
          }
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationFormik01">
              <Form.Label>Ism</Form.Label>
              <Form.Control
              ref={fnameRef}
                type="text"
                name="firstName"
                value={fname}
                onChange={(e) => setFname(e.target.value)}
                isValid={fname}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="validationFormik02">
              <Form.Label>Familiya</Form.Label>
              <Form.Control
              ref={lnameRef}
              type="text"
              name="lastName"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              isValid={lname}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="validationFormikUsername">
              <Form.Label>Elektron pochta</Form.Label>
              <Form.Control
                ref={emailRef}
                  type="text"
                  aria-describedby="inputGroupPrepend"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isValid={email}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationFormik03">
              <Form.Label>Telefon</Form.Label>
              <Form.Control
              ref={phoneRef}
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                isValid={phone}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


            <Form.Group className='mb-3 mt-3'>
              <FormLabel style={{color:studentDocuments.length === 0 ? 'red':'black'}}>Pasport</FormLabel>
            <StorageManager
              acceptedFileTypes={['*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setStudentDocuments([...studentDocuments,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>

            <Form.Group className='mb-3 mt-3'>
              <FormLabel style={{color:studentDocuments.length === 0 ? 'red':'black'}}>Passport rasm (5/5)</FormLabel>
            <StorageManager
              acceptedFileTypes={['image/*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setPhoto([...photo,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>
         
            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Ko'cha</Form.Label>
              <Form.Control
              ref={streetRef}
                type="text"
                name="street"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                isValid={street}
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Shahar</Form.Label>
              <Form.Control
              ref={cityRef}
                type="text"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                isValid={city}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Viloyat</Form.Label>
              <Form.Control
              ref={stateRef}
                type="text"
                name="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                isValid={state}
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Pochta Kodi</Form.Label>
              <Form.Control
              ref={zipcodeRef}
                type="text"
                name="zip"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                isValid={zip}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


          </Row>
          <Form.Group className="mb-3">
            <Form.Check
              required
              name="terms"
              label="Talablarga rozi bulish"
              onChange={(e) => setTerms(e.target.checked)}
              isValid={terms}
              isInvalid={!terms}
              feedback={terms}
              feedbackType="invalid"
              id="validationFormik0"
            />
          </Form.Group>



         <Form.Group className='d-grid gap-2'>
         <Button type="submit">Davom etish</Button>
         </Form.Group>
        </Form>
      </Tab>







      <Tab eventKey="contact" title="RU" onClick={() => setLanguage('ru')}>
      <Form  
        onSubmit={handleFormSubmit}>
          <h1 className="center">
          Форма заявки
          </h1>
          {
            errorMessage && <p style={{color:'red'}}>{errorMessage}</p>
          }
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationFormik01">
              <Form.Label>Имя</Form.Label>
              <Form.Control
              ref={fnameRef}
                type="text"
                name="firstName"
                value={fname}
                onChange={(e) => setFname(e.target.value)}
                isValid={fname}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="validationFormik02">
              <Form.Label>Фамилия</Form.Label>
              <Form.Control
              ref={lnameRef}
              type="text"
              name="lastName"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              isValid={lname}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="validationFormikUsername">
              <Form.Label>Электронная почта</Form.Label>
              <Form.Control
                ref={emailRef}
                  type="text"
                  aria-describedby="inputGroupPrepend"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isValid={email}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationFormik03">
              <Form.Label>Телефон</Form.Label>
              <Form.Control
              ref={phoneRef}
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                isValid={phone}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


            <Form.Group className='mb-3 mt-3'>
              <FormLabel style={{color:studentDocuments.length === 0 ? 'red':'black'}}>Загрузить паспорт</FormLabel>
            <StorageManager
              acceptedFileTypes={['*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setStudentDocuments([...studentDocuments,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>

            <Form.Group className='mb-3 mt-3'>
              <FormLabel style={{color:studentDocuments.length === 0 ? 'red':'black'}}>Фотография на паспорт (5/5)</FormLabel>
            <StorageManager
              acceptedFileTypes={['image/*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setPhoto([...photo,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Улица</Form.Label>
              <Form.Control
              ref={streetRef}
                type="text"
                name="street"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                isValid={street}
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Город</Form.Label>
              <Form.Control
              ref={cityRef}
                type="text"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                isValid={city}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Состояние</Form.Label>
              <Form.Control
              ref={stateRef}
                type="text"
                name="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                isValid={state}
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Почтовый индекс</Form.Label>
              <Form.Control
              ref={zipcodeRef}
                type="text"
                name="zip"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                isValid={zip}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

        

          </Row>
          <Form.Group className="mb-3">
            <Form.Check
              required
              name="terms"
              label="Согласен с условиями"
              onChange={(e) => setTerms(e.target.checked)}
              isValid={terms}
              isInvalid={!terms}
              feedback={terms}
              feedbackType="invalid"
              id="validationFormik0"
            />
          </Form.Group>



         <Form.Group className='d-grid gap-2'>
         <Button type="submit">Продолжать</Button>
         </Form.Group>
        </Form>
      </Tab>

    </Tabs>



   <Footer />
     
  </Container>
  );
}

